<script>
import api from '@/services/api';
import {
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BModal,
    BRow,
    BTable,
    VBTooltip
} from 'bootstrap-vue';
import TableActionBtns from '@/components/TableActionBtns';
import PageTitle from '@/components/PageTitle';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import vSelect from 'vue-select';

export default {
    name: 'QuestionnaireIndex',
    components: {
        PageTitle,
        TableActionBtns,
        BRow,
        BTable,
        BCol,
        BButton,
        BModal,
        BFormGroup,
        BFormInput,
        vSelect
    },
    mixins: [CRUDPageMixin],
    directives: {
        'b-tooltip': VBTooltip
    },
    data: () => ({
        surveys: [],
        organizations: [],
        merchantOptions: [],
        dataForModal: {
            name: '',
            merchant: null
        },
        loading: false
    }),
    computed: {
        organizationsTableHeader() {
            return [
                {
                    key: 'id',
                    label: 'ID'
                },
                {
                    key: 'name',
                    label: this.$t('titles.title')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        }
    },
    methods: {
        async getOrganizations() {
            const { data } = await api.organizations.getOrganizationsApi();
            this.organizations = data.result;
        },

        async submitOrganization() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    let body = this.dataForModal;
                    body.merchant_id = body.merchant?.id;
                    delete this.dataForModal.merchant;

                    try {
                        if (this.updatingItem) {
                            await this.update(body);
                        } else {
                            await this.create(body);
                        }
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    } finally {
                        this.isOpenModal = false;
                        await this.getOrganizations();
                    }
                }
            });
        },
        async create(body) {
            const { data } = await api.organizations.createOrganizationApi(body);
            this.organizations.push(data.result);
            this.$toast.success(this.$t('success.added'));
        },

        async update(body) {
            const { data } = await api.organizations.updateOrganizationApi(
                this.updatingItem.id,
                body
            );
            this.$toast.success(this.$t('success.updated.title'));
            this.updateDataToNewData(data.result);
        },

        async deleteOrganization(id, index) {
            try {
                await api.organizations.deleteOrganizationApi(id);
                this.organizations.splice(index, 1);
                this.$toast.success(this.$t('success.deleted'));
            } catch (e) {
                this.$toast.error(e.message);
            }
        },

        openOrganizationCreateModal() {
            this.isOpenModal = true;
        },

        async getMerchants() {
            const params = {
                method: 'coin.merchant_get_all',
                params: {
                    page: 1,
                    limit: 10
                }
            };

            await api.coin
                .fetchCoinMerchants(params)
                .then((res) => {
                    this.merchantOptions = res.data.result;
                    this.apps = [{ id: null, name: 'All' }, ...res.data.result];
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    },
    mounted() {
        this.loading = true;
        this.getOrganizations();
        this.getMerchants();
        this.loading = false;
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title>
                {{ $t('navigation.organizations') }}
            </template>
            <template v-if="hasAccess('vote_organizations', 'create')" #button>
                <b-button class="ml-auto" variant="success" @click="openOrganizationCreateModal">
                    {{ $t('button.add') }}
                    <feather-icon icon="PlusIcon" />
                </b-button>
            </template>
        </page-title>

        <b-row>
            <!--  TABLE  -->
            <b-col cols="12">
                <div class="card">
                    <progress-linear v-if="loading" />
                    <b-table
                        :fields="organizationsTableHeader"
                        :items="organizations"
                        :responsive="true"
                        borderless
                        outlined
                        striped
                    >
                        <template #cell(actions)="{ item, index }">
                            <div class="d-flex justify-content-end">
                                <b-button
                                    v-if="hasAccess('vote_surveys', 'view')"
                                    v-b-tooltip.hover.top="`${$t('button.open')}`"
                                    :to="{
                                        name: 'organizations-show',
                                        params: {
                                            id: item.id
                                        }
                                    }"
                                    class="btn-icon"
                                    variant="flat-dark"
                                >
                                    <feather-icon icon="ArrowRightCircleIcon" />
                                </b-button>
                                <table-action-btns
                                    :delete-access="{
                                        section: 'vote_organizations',
                                        permission: 'delete'
                                    }"
                                    :delete-handler="deleteOrganization"
                                    :index="index"
                                    :item="item"
                                    :update-access="{
                                        section: 'vote_organizations',
                                        permission: 'update'
                                    }"
                                    @openModal="openModalToUpdate"
                                />
                            </div>
                        </template>
                    </b-table>
                </div>
            </b-col>
        </b-row>

        <b-modal
            id="createUpdateModal"
            v-model="isOpenModal"
            :hide-footer="false"
            :no-close-on-backdrop="true"
            :title="$t('add.organization')"
            cancel-variant="danger"
            centered
            size="md"
            @hidden="onCloseModal"
        >
            <ValidationObserver ref="createUpdateModal">
                <b-row>
                    <!--    ORGANIZATION NAME    -->
                    <b-col cols="12">
                        <label>
                            {{ $t('titles.title') }}
                        </label>
                        <ValidationProvider
                            v-slot="{ errors }"
                            :name="$t('titles.title')"
                            rules="required"
                        >
                            <b-form-group>
                                <b-form-input
                                    v-model="dataForModal.name"
                                    :placeholder="$t('enter.title')"
                                    :state="errors.length > 0 ? false : null"
                                    size="lg"
                                />
                            </b-form-group>
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>

                    <b-col cols="12">
                        <ValidationProvider v-slot="{ errors }" name="Merchant" rules="required">
                            <label>Merchant</label>
                            <v-select
                                v-model="dataForModal.merchant"
                                :options="merchantOptions"
                                label="name"
                            />
                            <span class="validation__red">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>
                    </b-col>
                </b-row>
            </ValidationObserver>
            <template #modal-footer>
                <!--                <b-form-checkbox v-if="!updatingItem" v-model="createOneMore" class="mr-auto">-->
                <!--                    {{ $t('titles.add_more') }}-->
                <!--                </b-form-checkbox>-->
                <b-button variant="success" @click="submitOrganization">
                    {{ modalSubmitBtnText }}
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
